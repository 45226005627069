@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat Alternates', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --blue: #3a86ff;
  --dark-blue: #2d6ed5;
  --yellow: #ffbe0b;
  --dark-yellow: #e4a803;
  --pink: #ff006e;
  --gray: #888;
  --error: #f4496d;
}


.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #dbdcef #0000;
  animation: spinner-e04l1k 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
}

.spinner::before {
  border-color: var(--dark-blue) #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.spinner::after {
  margin: 8.9px;
}

@keyframes spinner-e04l1k {
  100% {
     transform: rotate(1turn);
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--blue);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark-blue);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-yellow);
}

.bg-main {
  background-color: var(--blue);
}

.bg-second{
  @apply bg-[var(--yellow)];
}

.menu-item {
  color: #fff;
}

.menu-item:hover {
  background-color: var(--dark-blue);
  border-radius: 30px 0px;
}

.menu-active {
  font-weight: bold;
  background-color: var(--dark-blue);
  border-radius: 30px 0px;
}

.text-main {
  color: var(--blue);
}

.text-secondary {
  color: var(--yellow);
}

.card-editor-background {
  background: linear-gradient(
      to right,
      rgba(109, 106, 106, 0.25) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(109, 106, 106, 0.25) 1px, transparent 1px);
  background-size: 8px 8px;
}

.MuiPopover-root .MuiPaper-root {
  max-height: 300px;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--pink) !important; /* Change to your desired color */
}
