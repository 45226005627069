/* src/CustomCalendar.css */

/* Change color for the selected date */
.react-calendar__tile--active {
  background: var(--pink) !important; /* Change to your desired color */
  color: white !important;
  font-family: inherit;
}

/* Change color for today's date */
.react-calendar__tile--now {
  background: var(--blue) !important; /* Change to your desired color */
  color: white !important;
  font-family: inherit;
}

/* Optional: Add hover effect */
.react-calendar__tile--active:hover,
.react-calendar__tile--now:hover {
  background: inherit; /* Change to your desired hover color */
  color: white !important;
}

/* Remove border for all tiles */
.react-calendar__tile {
  border: none !important;
  box-shadow: none !important; /* Remove shadow if any */
  font-family: inherit;
}

/* Remove border for the calendar itself */
.react-calendar {
  border: none !important;
  box-shadow: none !important; /* Remove shadow if any */
}

.react-calendar__month-view__weekdays {
  border: none !important; /* Remove border around the entire weekdays container */
  font-family: inherit;
}

.react-calendar__month-view__weekdays__weekday {
  border: none !important; /* Remove border around each individual weekday name */
  font-family: inherit;
}

/* Optional: Adjust padding or margin if needed */
.react-calendar__month-view__weekdays__weekday abbr {
  padding: 0 !important;
  margin: 0 !important;
}

/* src/CustomCalendar.css */

/* Make the calendar container responsive */
.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Ensure the calendar is responsive */
.react-calendar {
  width: 100%;
  max-width: 600px;
  border: none; /* Remove border for the entire calendar */
  box-shadow: none; /* Remove shadow if any */
}

/* Remove border from the day names */
.react-calendar__month-view__weekdays {
  border: none; /* Remove border around the entire weekdays container */
}

.react-calendar__month-view__weekdays__weekday {
  border: none; /* Remove border around each individual weekday name */
}

/* Adjust font size and spacing for better responsiveness */
.react-calendar__tile {
  max-width: 100%;
  font-size: 1rem;
}
.react-calendar__tile:focus,
.react-calendar__tile:hover {
  background-color: inherit;
}

abbr:where([title]) {
  text-decoration: none;
}

/* Adjust font size for smaller screens */
@media (max-width: 768px) {
  .react-calendar__tile {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .react-calendar__tile {
    font-size: 0.6rem;
    padding-left: 0px;
    padding-right: 0px;
    width: 5px;
  }
}
